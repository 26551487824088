
















































































import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { selectCustomerService } from "@/services/internal-only/select-customer.service";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import AscendLogo from "@/components/design-system/icons/AscendLogo.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { Customer } from "@/models/user/customer";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import AutomationAnalyticsStore from "@/store/reporting-analytics/automation.store";
import InvoicesAnalyticsStore from "@/store/reporting-analytics/invoices.store";
import InvoiceVolumeAnalyticsStore from "@/store/reporting-analytics/invoice-volume.store";
import RecognitionAnalyticsStore from "@/store/reporting-analytics/recognition-analytics.store";
import RecognitionRateAnalyticsStore from "@/store/reporting-analytics/recognition-rate.store";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "secondary-button": SecondaryButton,
    "ascend-logo": AscendLogo
  }
})
export default class SelectCustomer extends UserRolesMixin {
  private selectedCustomer: Customer | null = null;
  private customerQuery: string | number = "";
  private customerOptions = [];

  // analytics store modules
  private readonly automationAnalyticsStore: AutomationAnalyticsStore = getModule(
    AutomationAnalyticsStore,
    this.$store
  );
  private readonly invoicesAnalyticsStore: InvoicesAnalyticsStore = getModule(
    InvoicesAnalyticsStore,
    this.$store
  );
  private readonly invoiceVolumeAnalyticsStore: InvoiceVolumeAnalyticsStore = getModule(
    InvoiceVolumeAnalyticsStore,
    this.$store
  );
  private readonly recognitionAnalyticsStore: RecognitionAnalyticsStore = getModule(
    RecognitionAnalyticsStore,
    this.$store
  );
  private readonly recognitionRateStore: RecognitionRateAnalyticsStore = getModule(
    RecognitionRateAnalyticsStore,
    this.$store
  );

  created() {
    if (!this.isAscendUser) {
      checkCurrentRouteAndRedirect(this.$router, "/403/access");
    }
    if (this.userStore.getSelectedCustomer.id) {
      // reset customer so that default region is used for customer lookup
      this.userStore.setSelectedCustomer({
        id: null,
        description: "",
        displayValue: "",
        region: ""
      });
    }
  }

  private goHome() {
    checkCurrentRouteAndRedirect(this.$router, "/");
  }

  private resetAnalytics() {
    this.automationAnalyticsStore.resetToInitialState();
    this.invoicesAnalyticsStore.resetToInitialState();
    this.invoiceVolumeAnalyticsStore.resetToInitialState();
    this.recognitionRateStore.resetToInitialState();
    this.recognitionAnalyticsStore.resetToInitialState();
  }

  @Watch("customerQuery")
  async lookup() {
    if (this.customerQuery && typeof this.customerQuery == "string") {
      const response = await selectCustomerService.lookup(this.customerQuery);
      this.customerOptions = response.data;
    }
  }

  @Watch("selectedCustomer")
  async validate() {
    if (this.selectedCustomer) {
      this.userStore.setSelectedCustomer(this.selectedCustomer);

      this.resetAnalytics();
    }
  }
}
