import { app } from "@/main";

class SelectCustomerService {
  public async lookup(query: string) {
    const response = await app.$ravenapi.get(
      `api/lookup/customer?value=${query}`
    );
    return response["data"];
  }
}

export const selectCustomerService = new SelectCustomerService(); // singleton
